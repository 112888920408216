import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomeReel from "./components/HomeReel/HomeReel";
import UserProfile from "./components/UserProfile/UserProfile";
import ServerMaintenance from "./components/ServerMaintenance/ServerMaintenance";

function App() {
  const [isServerMaintenance, setIsServerMaintenance] = useState(false);
  const checkServerHealth = async () => {
    try {
      let data = await fetch(
        `${process.env.REACT_APP_MAIN_API_BASE_URL}/api/v1/health`
      );
      data = await data.json();
      setIsServerMaintenance(data?.result?.isMaintenance);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkServerHealth();

    function setContainerHeight() {
      const viewportHeight = window.innerHeight; // Get the viewport height
      const container = document.getElementsByClassName("container")[0]; // Get the container element
      container.style.height = `${viewportHeight}px`; // Set the container height
    }

    // Call the function initially and on window resize
    setContainerHeight();
    window.addEventListener("resize", setContainerHeight);
  }, []);

  return (
    <Router>
      <Routes>
        {!isServerMaintenance ? (
          <>
            <Route exact path="/" element={<HomeReel setIsServerMaintenance={setIsServerMaintenance} />} />
            <Route
              exact
              path="/user/profile/:username"
              element={<UserProfile />}
            />
          </>
        ) : (
          <>
            <Route exact path="/*" element={<ServerMaintenance />} />
          </>
        )}
      </Routes>
    </Router>
  );
}

export default App;
