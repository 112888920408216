import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faPlus,faUser, faSearch, faSave } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function BottomNavbar() {

  const notify = () => toast.info("🎉🎉 Comming Soon! 🎉🎉",{
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    toastId: 1
    });

  return (
      <div className="bottom-navbar">
        <div className="nav-item">
          <FontAwesomeIcon icon={faHouse} className="icon active" />
          <span className="item-name active">Home</span>
        </div>
        <div onClick={notify} className="nav-item">
          <FontAwesomeIcon icon={faSearch} className="icon" />
          <span className="item-name">Search</span>
        </div>
        <div onClick={notify} className="nav-item">
          <FontAwesomeIcon icon={faPlus} className="icon plus" />
          <span className="item-name">Create</span>
        </div>
        <div onClick={notify} className="nav-item">
          {/* <FontAwesomeIcon icon={fa7} className="notification" /> */}
          <FontAwesomeIcon icon={faSave} className="icon" />
          <span className="item-name">Save</span>
        </div>
        <div onClick={notify} className="nav-item">
          <FontAwesomeIcon icon={faUser} className="icon" />
          <span className="item-name">Profile</span>
        </div>
      </div>
  );
}

export default BottomNavbar;
