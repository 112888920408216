import React, { useEffect, useState, useRef } from "react";
import "./UserProfile.css";
import VideoCard from "../VideoCard";
import BottomNavbar from "../BottomNavbar";
import TopNavbar from "../TopNavbar";
import { ToastContainer } from "react-toastify";
import { useParams } from "react-router-dom";
import { fetchImage, fetchVideo } from "../../utils/fetchImage";
import TopBar from "../TopBar/TopBar";
import { formatNumber } from "../../utils/formateNumber";

function UserProfile() {
  let { username } = useParams();
  const [profileData, setProfileData] = useState();
  const [profileImage, setProfileImage] = useState(null);
  const [posts, setPosts] = useState(null);
  const [videoThumbnail, setVideoThumbnail] = useState([]);

  const fetchData = async () => {
    let data = await fetch(
      `${process.env.REACT_APP_MAIN_API_BASE_URL}/api/v1/profile?username=${username}`
    );
    data = await data.json();
    setProfileData(data.result);
    fetchImageData(data?.result?.thumbnailUrl);
  };

  const fetchImageData = async (image) => {
    let profileImage = await fetchImage(image);
    setProfileImage(profileImage);
  };
  const fetchVideoThumbnail = async (post) => {
    let thumbnailArray = [];
    for(let i=0;i<post.length;i++){
      let thumbnail = "";
        if(post[i]?.thumbnailSdUrl){
          thumbnail = await fetchImage(post[i]?.thumbnailSdUrl);
        }else{
          thumbnail = await fetchVideo(post[i]?.thumbnailStreamUrl);
        }
        thumbnailArray.push(thumbnail)
    }
    setVideoThumbnail(thumbnailArray);
  };

  const fetchPosts = async () => {
    let data = await fetch(
      `${process.env.REACT_APP_MAIN_API_BASE_URL}/api/v1/profile/posts?username=${username}&amount=`
    );
    data = await data.json();
    setPosts(data.result);
    fetchVideoThumbnail(data.result);
  };

  useEffect(() => {
    fetchData();
    fetchPosts();
  }, []);

  return (
    <div className="app">
      <div className="container">
        <div className="userprofile-page">
          <TopBar username={profileData?.username} />
          <div className="profile">
            <div className="profile-section">
              <div className="profile-img">
                <img src={profileImage} alt="" />
              </div>
            </div>
            <div className="profile-data">
              <div className="data">
                <span>{formatNumber(profileData?.countPosts)}</span>
                <label htmlFor="">Clips</label>
              </div>
              <div className="data">
                <span>{formatNumber(profileData?.countIncomingFollows)}</span>
                <label htmlFor="">Followers</label>
              </div>
              <div className="data">
                <span>{formatNumber(profileData?.countTotalViews)}</span>
                <label htmlFor="">Views</label>
              </div>
            </div>
            <div className="profile-about">
              {profileData?.description}
            </div>
          </div>
          <div className="posts">
            <div className="posts-container">
              {posts?.map((post, index) => {
                return (
                  <div key={index} className="post-data">
                    <div className="post-img">
                      <img
                        src={videoThumbnail[index]}
                        alt=""
                        srcset=""
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserProfile;
