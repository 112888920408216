import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faEye } from "@fortawesome/free-solid-svg-icons";
import "./FooterRight.css";
import { useNavigate } from "react-router-dom";

function FooterRight({ username,likes, views, saves, shares, profilePic }) {
  const navigate = useNavigate();
  const [liked, setLiked] = useState(false);
  const [profileImage, setProfileImage] = useState(null);

  // Function to convert likes count to a number
  const parseLikesCount = (count) => {
    if (typeof count === "string") {
      if (count.endsWith("K")) {
        return parseFloat(count) * 1000;
      }
      return parseInt(count);
    }
    return count;
  };

  // Function to format likes count
  const formatLikesCount = (count) => {
    if (count >= 1e9) {
      return (Number(count) / 1e9).toFixed(1) + "B";
    } else if (count >= 1e6) {
      return (Number(count) / 1e6).toFixed(1) + "M";
    } else if (count >= 1000) {
      return (Number(count) / 1000).toFixed(1) + "K";
    }
    return count;
  };

  const handleLikeClick = () => {
    setLiked((prevLiked) => !prevLiked);
  };

  const fetchImage = async () => {
    try {
      let data = await fetch(
        `${
          process.env.REACT_APP_MAIN_API_BASE_URL
        }/api/v1/fetchStreamData?url=${encodeURIComponent(profilePic)}`
      );
      let blob = await data.blob();
      const blobUrl = URL.createObjectURL(blob);
      setProfileImage(blobUrl);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchImage();
  }, []);

  return (
    <div className="footer-right">
      <div className="sidebar-icon" onClick={()=> navigate(`/user/profile/${username}`)}>
        {profileImage ? (
          <img
            src={profileImage}
            className="userprofile"
            alt="Profile"
            style={{ width: "45px", height: "45px", color: "#616161" }}
          />
        ) : null}
        {/* <FontAwesomeIcon icon={userAddIcon} className='useradd' style={{ width: '15px', height: '15px', color: '#FF0000' }} onClick={handleUserAddClick}/> */}
      </div>
      <div className="sidebar-icon">
        <FontAwesomeIcon
          icon={faHeart}
          style={{
            width: "35px",
            height: "35px",
            color: liked ? "#FF0000" : "white",
          }}
          onClick={handleLikeClick}
        />
        <p>{formatLikesCount(parseLikesCount(likes) + (liked ? 1 : 0))}</p>
      </div>
      <div className="sidebar-icon">
        <FontAwesomeIcon
          icon={faEye}
          style={{ width: "35px", height: "35px", color: "white" }}
        />
        <p>{formatLikesCount(parseLikesCount(views))}</p>
      </div>

      <div className="sidebar-icon record">
        <img
          src="https://static.thenounproject.com/png/934821-200.png"
          alt="Record Icon"
        />
      </div>
    </div>
  );
}

export default FooterRight;
