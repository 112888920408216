import React from "react";
import "./ServerMaintenance.css";
import Logo from "../../assets/images/pnlogo.png";

const ServerMaintenance = () => {
  return (
    <div className="app">
      <div className="container">
        <div className="maintenance">
          <div className="logo">
            <img src={Logo} alt="" srcset="" />
          </div>
          <div className="heading">
            Insta | <span>Porn</span>
          </div>
          <div className="heading">We're improving things for you!</div>
          <p>
            We're currently down for planned maintenance. We're working on
            making Insta Porn even better and will be back shortly.
          </p>
          <p>
            We appreciate your patience and apologize for any inconvenience
            caused.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ServerMaintenance;
