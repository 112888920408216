export const fetchImage = async (profilePic) => {
    try {
      let data = await fetch(
        `${
          process.env.REACT_APP_MAIN_API_BASE_URL
        }/api/v1/fetchStreamData?url=${encodeURIComponent(profilePic)}`
      );
      let blob = await data.blob();
      const blobUrl = URL.createObjectURL(blob);
      return blobUrl;
    } catch (error) {
      console.log(error);
    }
  };

export const fetchVideo = async (video) => {
    try {
      let data = await fetch(
        `${
          process.env.REACT_APP_MAIN_API_BASE_URL
        }/api/v1/fetchStreamVideo?url=${encodeURIComponent(video)}`
      );
      let blob = await data.blob();
      const blobUrl = URL.createObjectURL(blob);
      return blobUrl;
    } catch (error) {
      console.log(error);
    }
  };