import React, { useEffect, useState, useRef } from "react";
import VideoCard from "../VideoCard";
import BottomNavbar from "../BottomNavbar";
import TopNavbar from "../TopNavbar";
import { ToastContainer } from "react-toastify";

function HomeReel({ setIsServerMaintenance }) {
  const [videos, setVideos] = useState([]);
  const videoRefs = useRef([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [fetchingMore, setFetchingMore] = useState(true);

  const [userAgent, setUserAgent] = useState("");

  useEffect(() => {
    setUserAgent(navigator.userAgent);
  }, []);

  const fetchData = async () => {
    let data = await fetch(
      `${process.env.REACT_APP_MAIN_API_BASE_URL}/api/v1/random/posts?amount=5`
    );
    data = await data.json();
    if (data?.result) {
      setVideos((prevVideos) => [...prevVideos, ...data?.result]);
    } else if (data?.result?.message === "Service Unavailable") {
      setIsServerMaintenance(true);
    } else if (data?.result?.isMaintenance) {
      setIsServerMaintenance(true);
    } else {
      setIsServerMaintenance(true);
    }
    setIsLoadingData(false);
    setFetchingMore(false);
  };

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.8, // Adjust this value to change the scroll trigger point
    };

    // This function handles the intersection of videos
    const handleIntersection = (entries) => {
      try {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const videoElement = entry.target;
            if (!/iPhone|iPad|iPod/.test(userAgent)) {
              videoElement.play();
            }
            if (
              videoElement === videoRefs.current[videoRefs.current.length - 2]
            ) {
              // If the last video is intersecting, trigger fetchMore
              setFetchingMore(true);
            }
          } else {
            const videoElement = entry.target;
            if (!/iPhone|iPad|iPod/.test(userAgent)) {
              videoElement.pause();
            }
          }
        });
      } catch (error) {
        console.error(error);
      }
    };

    const observer = new IntersectionObserver(
      handleIntersection,
      observerOptions
    );

    // We observe each video reference to trigger play/pause
    videoRefs.current.slice(1).forEach((videoRef) => {
      observer.observe(videoRef);
    });

    // We disconnect the observer when the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, [videos]);

  // This function handles the reference of each video
  const handleVideoRef = (index) => (ref) => {
    videoRefs.current[index] = ref;
  };

  // const fetchMore = async () => {
  //   console.log("Load More");
  // };

  useEffect(() => {
    if (fetchingMore) {
      fetchData();
    }
  }, [fetchingMore]);

  return (
    <div className="app">
      <div className="container">
        <TopNavbar className="top-navbar" />
        {/* Here we map over the videos array and create VideoCard components */}
        <div className="mh-100">
          {isLoadingData && (
            <div>
              <img
                style={{
                  position: "absolute",
                  left: "50%",
                  height: "70px",
                  width: "70px",
                  padding: "15px",
                  top: "50%",
                  borderRadius: "50%",
                  transform: "translate(-50%, -50%)",
                }}
                src="https://i.gifer.com/ZKZg.gif"
                alt=""
                srcset=""
              />
            </div>
          )}
          {videos?.map((video, index) => (
            <VideoCard
              key={index}
              username={video?.author?.username}
              description={video?.label}
              song={video?.song}
              likes={video?.likesCount}
              saves={video?.saves}
              views={video?.viewsCount}
              shares={video?.shares}
              url={video?.videoFileOriginalUrl}
              profilePic={video?.author?.thumbnailUrl}
              setVideoRef={handleVideoRef(index)}
              autoplay={index > 0 ? true : false}
            />
          ))}
        </div>
        <BottomNavbar className="bottom-navbar" />
        <ToastContainer />
      </div>
    </div>
  );
}

export default HomeReel;
