import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const TopBar = ({ username }) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="top-bar">
        <div className="back-btn">
          <FontAwesomeIcon onClick={()=> navigate(-1)} icon={faArrowLeft} />
        </div>
        <div className="username">@{username}</div>
        <div className="right"></div>
      </div>
    </div>
  );
};

export default TopBar;
