export function formatNumber(number=0) {
    const units = ['', 'K', 'M', 'B', 'T'];
    const unitIndex = Math.floor((number.toString().length - 1) / 3);
    const unit = units[unitIndex];
    let formattedNumber = "";
    if(number > 1000){
        formattedNumber = (number / Math.pow(1000, unitIndex)).toFixed(2);
    }else{
        formattedNumber = number
    }
    return `${formattedNumber}${unit}`;
}