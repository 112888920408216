import React, { useRef, useEffect, useState } from "react";
import FooterLeft from "./FooterLeft";
import FooterRight from "./FooterRight";
import "./VideoCard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { generateVideoPoster } from "../utils/generateVideoPoster";

const VideoCard = (props) => {
  const {
    url,
    username,
    description,
    song,
    likes,
    shares,
    views,
    saves,
    profilePic,
    setVideoRef,
    autoplay,
  } = props;
  const videoRef = useRef(null);

  const [videoUrl, setVideoUrl] = useState(null);
  const [isPauseBtnOn, setIsPauseBtnOn] = useState(false);
  const [isLoadingVideo, setIsLoadingVideo] = useState(true);
  const [posterUrl, setPosterUrl] = useState("");

  useEffect(() => {
    try {
      if (autoplay) {
        if (!/iPhone|iPad|iPod/.test(navigator.userAgent)) {
          videoRef?.current?.play();
        }
      }
    } catch (error) {}
  }, [autoplay]);

  const onVideoPress = () => {
    try {
      if (videoRef.current.paused) {
        setIsPauseBtnOn(false);
        videoRef.current.play();
      } else {
        videoRef.current.pause();
        setIsPauseBtnOn(true);
      }
    } catch (error) {}
  };

  const fetchVideo = async () => {
    try {
      setIsLoadingVideo(true);
      let data = await fetch(
        `${
          process.env.REACT_APP_MAIN_API_BASE_URL
        }/api/v1/fetchStreamData?url=${encodeURIComponent(url)}`
      );
      let blob = await data.blob();
      // const blobUrl = URL.createObjectURL(blob);
      const videoBlob = new Blob([blob], { type: "video/mp4" });
      const blobUrl = URL.createObjectURL(videoBlob);
      setVideoUrl(blobUrl);
      setIsLoadingVideo(false);
      setIsPauseBtnOn(!autoplay);
      if (/iPhone|iPad|iPod/.test(navigator.userAgent)) {
        setIsPauseBtnOn(true);
      }
      const timeInSeconds = 2; 

    generateVideoPoster(blobUrl, timeInSeconds, (posterUrl) => {
      setPosterUrl(posterUrl);
    });
    } catch (error) {
      alert("Error: " + error);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchVideo();

    function setContainerHeight() {
      const viewportHeight = window.innerHeight;
      const players = document.getElementsByClassName("video");
      Array.from(players).forEach((player) => {
        player.style.height = `${viewportHeight}px`;
      });
    }

    // Call the function initially and on window resize
    setContainerHeight();
    window.addEventListener("resize", setContainerHeight);
  }, []);

  return (
    <div className="video">
      {/* The video element */}
      <video
        className="player"
        onClick={onVideoPress}
        ref={(ref) => {
          videoRef.current = ref;
          setVideoRef(ref);
        }}
        loop
        playsInline // Necessary for inline playback on iOS
        src={videoUrl}
        poster={posterUrl}
      ></video>
      {isPauseBtnOn && (
        <FontAwesomeIcon
          icon={faPlay}
          onClick={onVideoPress}
          style={{
            border: "1px solid #eee",
            position: "absolute",
            left: "50%",
            height: "35px",
            width: "35px",
            padding: "15px",
            color: "#fff",
            top: "50%",
            borderRadius: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      )}

      {isLoadingVideo && (
        <div>
          <img
            style={{
              position: "absolute",
              left: "50%",
              height: "70px",
              width: "70px",
              padding: "15px",
              top: "50%",
              borderRadius: "50%",
              transform: "translate(-50%, -50%)",
            }}
            src="https://i.gifer.com/ZKZg.gif"
            alt=""
            srcset=""
          />
        </div>
      )}
      <div className="bottom-controls">
        <div className="footer-left">
          {/* The left part of the container */}
          <FooterLeft
            username={username}
            description={description}
            song={song}
          />
        </div>
        <div className="footer-right">
          {/* The right part of the container */}
          <FooterRight
            username={username}
            likes={likes}
            shares={shares}
            views={views}
            saves={saves}
            profilePic={profilePic}
          />
        </div>
      </div>
    </div>
  );
};

export default VideoCard;
