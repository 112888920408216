export function generateVideoPoster(blobVideo, timeInSeconds, callback) {
  const video = document.createElement("video");
  video.src = blobVideo;
  video.addEventListener("loadedmetadata", () => {
    video.currentTime = timeInSeconds;
    video.addEventListener("seeked", () => {
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      canvas.toBlob((blob) => {
        const posterUrl = URL.createObjectURL(blob);
        callback(posterUrl);
      }, "image/jpeg");
    });
  });
}